var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.matrix2_competences)?_c('div',{staticClass:"edit-section mb-3 mt-2"}):_vm._e(),(!_vm.matrix2_competences)?_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.competence',"allows_crud":_vm.allows_crud}})],1):_vm._e(),_c('div',{staticStyle:{"display":"flex"}},[(_vm.allows_cruds && _vm.user && _vm.user.is_superuser)?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud && _vm.user && _vm.user.is_superuser)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_competence'),expression:"'mesh.add_competence'"},{name:"b-modal",rawName:"v-b-modal.new-competence-modal",modifiers:{"new-competence-modal":true}}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
          'mesh.competence',
          false,
          'Competencia'
        )}`,"text_button":`Agregar`,"icon":'plus',"variant":'primary'}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"table-container"},[(!_vm.isBusy)?_c('GenericBTable',{key:_vm.keyGenericTable,attrs:{"items":_vm.filteredCompetences,"fields":_vm.fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"selection_mode":true,"columns_display":true,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
            _vm.all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'
          ),expression:"\n            all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'\n          ",modifiers:{"v-secondary":true}}],on:{"input":_vm.selectAll},model:{value:(_vm.all_selected),callback:function ($$v) {_vm.all_selected=$$v},expression:"all_selected"}})]},proxy:true},{key:"cell(selected)",fn:function(row){return [_c('b-checkbox',{on:{"input":function($event){return _vm.selectCompetence(row.item.id, $event)}},model:{value:(row.item.selected),callback:function ($$v) {_vm.$set(row.item, "selected", $$v)},expression:"row.item.selected"}})]}},{key:"cell(cycle)",fn:function(row){return [_vm._l(([_vm.competenceCycle(row.item.cycle)]),function(cycle){return [(cycle)?_c('span',{key:cycle.id},[_vm._v(_vm._s(cycle.name))]):_c('span',{key:cycle},[_vm._v("N/A")])]})]}},{key:"cell(profile_competence)",fn:function(row){return [_c('p',[_vm._v(" "+_vm._s(_vm.profileCompetenceMention(row.item))+" ")])]}},{key:"cell(full_sentence)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('SentenceContainer',{attrs:{"Sentence":row.item},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('span',{staticClass:"mr-1"},[(
                    row.item.profile_cycle_order &&
                    row.item.profile_cycle_order != 9999
                  )?_c('span',[_vm._v(_vm._s(row.item.profile_cycle_order)+".")]):_vm._e(),(row.item.order)?_c('span',[_vm._v(_vm._s(row.item.order))]):_vm._e(),(
                    row.item.sub_order > 0 &&
                    _vm.institution &&
                    _vm.institution.show_competence_sub_order
                  )?_c('span',[_vm._v("."+_vm._s(row.item.sub_order))]):_vm._e()])]},proxy:true}],null,true)})],1)]}},{key:"cell(type)",fn:function(row){return [(row.item.type && _vm.competenceType(row.item.type))?[_vm._l(([_vm.competenceType(row.item.type)]),function(type){return [(type)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(type.description),expression:"type.description",modifiers:{"v-secondary":true}}],key:type.id},[_vm._v(_vm._s(type.name))]):_vm._e()]})]:_vm._e()]}},{key:"cell(egress_profile)",fn:function(row){return [_c('div',{staticClass:"profiles-container"},[_vm._l((_vm.getEgressProfileCompetence(row.item.id)),function(profiles){return [_c('div',{key:profiles.id,staticClass:"profiles-content"},[_vm._v(" "+_vm._s(profiles.professional_title)+" "),(
                  !(
                    (_vm.institution &&
                      _vm.institution.internal_use_id == 'duoc_uc') ||
                    _vm.$debug_change_duoc
                  )
                )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+": ")]),_vm._v(_vm._s(profiles.cycle_name == "" ? "N/A" : profiles.cycle_name)+" ")]):_vm._e(),(profiles.mentions.length > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", profiles.mentions.length > 1, "Mención" ))+": ")]),_vm._v(_vm._s(profiles.mentions.length > 1 ? "(" + profiles.mentions.join(", ") + ")" : profiles.mentions[0])+" ")]):_vm._e()])]})],2),_c('div',{staticClass:"container-badge-profile d-flex"},[_c('h5',[_c('b-badge',{staticClass:"badge-profile",attrs:{"variant":"secondary","id":`badge-profile${row.item.id}`}},[_vm._v(" "+_vm._s(_vm.getEgressProfileCompetence(row.item.id).length)+" ")])],1)]),(_vm.getEgressProfileCompetence(row.item.id).length > 0)?_c('b-popover',{attrs:{"target":`badge-profile${row.item.id}`,"triggers":"hover","placement":"left"}},[_c('div',{staticClass:"badge-popover"},[_vm._l((_vm.getEgressProfileCompetence(row.item.id)),function(profiles){return [_c('div',{key:profiles.id,staticClass:"profiles-content"},[_vm._v(" "+_vm._s(profiles.professional_title)+" "),(
                    !(
                      (_vm.institution &&
                        _vm.institution.internal_use_id == 'duoc_uc') ||
                      _vm.$debug_change_duoc
                    )
                  )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.cycle", false, "Ciclo Formativo" ))+": ")]),_vm._v(_vm._s(profiles.cycle_name == "" ? "N/A" : profiles.cycle_name)+" ")]):_vm._e(),(profiles.mentions.length > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", profiles.mentions.length > 1, "Mención" ))+": ")]),_vm._v(_vm._s(profiles.mentions.length > 1 ? "(" + profiles.mentions.join(", ") + ")" : profiles.mentions[0])+" ")]):_vm._e()])]})],2)]):_vm._e()]}},{key:"cell(ra_base)",fn:function(row){return [_c('h5',{key:`ra-base-header-${row.item.id}`,class:{ 'pointer-class': _vm.allows_cruds && !_vm.selection_mode },attrs:{"id":`ra-base-header-${row.item.id}`},on:{"click":() => {
              if (_vm.allows_cruds && !_vm.selection_mode)
                _vm.$bvModal.show(`modal-base-ra-${row.item.id}`);
            }}},[(_vm.raBasesFiltered)?_c('b-badge',{staticClass:"badge",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.raBasesFiltered(row.item.id).length))]):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-base-ra-${row.item.id}`,"hide-footer":"","title":_vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBase',{attrs:{"competence_id":row.item.id}})],1),_c('b-popover',{key:`ra-base-tooltip-${row.item.id}`,attrs:{"target":`ra-base-header-${row.item.id}`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base")))]},proxy:true}],null,true)},[(_vm.raBasesFiltered(row.item.id).length > 0)?[_c('div',{staticClass:"ra-competences-container"},_vm._l((_vm.raBasesFiltered(row.item.id)),function(ra){return _c('span',{key:ra.id},[_c('SentenceContainer',{attrs:{"Sentence":ra}})],1)}),0)]:[_vm._v("No cuenta con ningún "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" creado ")]],2)]}},(_vm.allows_cruds)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_competence'),expression:"'mesh.change_competence'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-competence-modal-${row.item.id}`),"icon":'square'}}),(_vm.allows_cruds && _vm.user && _vm.user.is_superuser)?_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_competence'),expression:"'mesh.delete_competence'"}],attrs:{"click_button":() => _vm.askForDeleteCompetence(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`,"icon":'trash'}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-competence-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetCompetenceTable}},[_c('CompetenceForm',{attrs:{"Competence":row.item,"selected_ids":_vm.getEgressProfiles(row.item.id),"show_title":false},on:{"updated":_vm.slotUpdatedCompetence,"close":_vm.slotCloseModal,"reset_competence":function($event){return _vm.changeCompetenceDescriptor(row.item.id)},"change_middle_descriptors":() => {
                _vm.changeCompetenceDescriptor(row.item.id);
              },"reset_competence_table":_vm.countCompetenceTable}})],1)]}}:null],null,true)}):_vm._e()],1),_c('div',[_c('b-modal',{attrs:{"id":"new-competence-modal","title":`Crear ${_vm.$getVisibleNames(
        'mesh.competence',
        false,
        'Competencia'
      )}`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetCompetenceTable}},[_c('CompetenceForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedCompetence,"close":_vm.slotCloseModal}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }